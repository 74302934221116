<template>
  <v-container>
    <v-card elevation="0" class="ma-0 pa-0">
      <v-card-title> Alta de Países </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent="submit" autocomplete="off">
          <v-card-text>
            <validation-provider
              v-slot="{ errors }"
              name="País"
              rules="required|min:4|max:50"
            >
              <v-text-field
                v-model="pais"
                :counter="50"
                :error-messages="errors"
                label="País"
                clearable
                autocomplete="off"
                ref="pais"
              ></v-text-field>
            </validation-provider>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col>
                <v-btn
                  class="mr-4"
                  type="submit"
                  block
                  plain
                  :disabled="invalid"
                >
                  Guardar
                </v-btn>
              </v-col>
              <v-col>
                <v-btn block plain @click="clear"> Limpiar </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-container>
</template>

<script>
import { required, max, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import axios from "axios";
import { mapState, mapActions } from "vuex";
setInteractionMode("aggressive");

extend("required", {
  ...required,
  message: "{_field_} no puede quedar vacio",
});

extend("max", {
  ...max,
  message: "{_field_} no puede tener más de {length} caractéres",
});

extend("min", {
  ...min,
  message: "{_field_} no puede tener menos de {length} caractéres",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    pais: ''
  }),
  computed: {
    ...mapState(["usuario"]),
  },
  methods: {
    ...mapActions(["setAlerta"]),

    async submit() {
      try {
        this.$refs.observer.validate();
        var response = await axios({
          url: process.env.VUE_APP_API + "parametrizacion/pais",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + this.usuario.token,
          },
          data: { txtDesc: this.pais },
        });
        if (response.data.estado == "OK") {
          this.setAlerta({
            snackbar_color: "success",
            text_snackbar:
              "Se agrego " +
              response.data.data.txtDesc.toUpperCase() +
              " como país.",
            snackbar: true,
          });
          this.clear();
        } else {
          this.setAlerta({
            snackbar_color: "warning",
            text_snackbar: response.data.data.error,
            snackbar: true,
          });
        }
      } catch (error) {
        this.setAlerta({
          snackbar_color: "danger",
          text_snackbar: error,
          snackbar: true,
        });
      }
    },
    clear() {
      this.pais = '';
      setTimeout(() => {
        this.$refs.observer.reset();
      }, 16);
      this.$refs.pais.focus();
    },
  },
};
</script>